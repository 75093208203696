<template>
  <div>
  </div>
</template>

<script>
import Event from '@/views/device/Event'

export default {
  name: 'SWFMEvent',
  extends: Event,
  methods: {
    getEventList() {
        var self = this
        var data = {
          is_closed: 0,
          device_guid: this.device_guid
        }
        // console.log('Event::getEventList data:', JSON.stringify(data))
        this.$store.dispatch('rest/method', {model:'events', method:'getAlarmList', data:data})
          .then((res) => {
            self.eventTable.data = [];
            res.forEach(function(row, index) {
              self.eventTable.data.push({
                created_at: row.created_at,
                description: row.description,
                service_type: row.service_type,
                sensor_type: row.sensor_type,
                sensor_id: row.sensor_id,
                is_closed: row.is_closed,
                level: row.level,
                sensor_value: row.sensor_value,
                device_guid: row.device_guid,
                device_name: _.get(row, "device.name"),
                device_address: _.get(row, "device.address"),
                site: _.get(row, "device.site_guid"),
                guid: row.guid,
              });
            })
            self.$emit('update', self.eventTable.data)
          })
          .catch((err) => {
            console.log('queryEvent event: ', err.toString())
            this.$refs.notifyDialog.show(err.toString())
          })
      }
  }
}
</script>